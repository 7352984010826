import {
    defineRule,
    configure,
    localize
} from 'vee-validate';

function capitalizeFirstLetter(str) {
    let strReplace = str.replaceAll('_', ' ');
    return strReplace.toLowerCase().replace(/\b./g, function(a) { return a.toUpperCase(); });
}

function containsDuplicates(arr) {
    if (arr.length !== new Set(arr).size) {
        return true;
    }

    return false;
}

function initializeFieldName(fld, ctx) {
    if (fld == undefined || fld == null) {
        fld = capitalizeFirstLetter(ctx.field);
    }
    return fld;
}

function calculateAge(birthDate, currentDate) {
    birthDate = new Date(birthDate);
    currentDate = new Date(currentDate);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    return age;
}


import moment from "moment";
import AllRules from '@vee-validate/rules';

export default {
    install(app) {
        Object.keys(AllRules).forEach(rule => {
            defineRule(rule, AllRules[rule]);
        });
        configure({
            generateMessage: (context) => {
                let field_name = capitalizeFirstLetter(context.field);
                const bytesToMegaBytes = bytes => bytes / 1024;
                const messages = {
                    required: `This field is required`,
                    size: `Maximum file upload should be ${bytesToMegaBytes(context.rule.params)} MB`,
                    min: `The ${  field_name } field must be at least ${context.rule.params} characters`,
                    max: `This field should not exceed ${context.rule.params} characters`,
                    alpha_spaces: `${  field_name } must be alphabets only`,
                    email: `The email address format is incorrect`,
                    min_value: `${field_name} should be start from ${  context.rule.params}`,
                    max_value: `This field ${field_name} is too high`,
                    excluded: 'This field is not allowed',
                    country_excluded: 'We do not allow users from this location',
                    password_mismatch: `This field ${  field_name } does not match`,
                    digits: `The ${  field_name } should be ${  context.rule.params} digits`,
                    mimes: `Select valid ${  context.rule.params}  ${  field_name }`,
                    between: `The  ${  field_name } field is invalid`,
                    optional: `This field is required`,
                    ext: 'Upload valid file format'
                };

                if (context.field == 'relation' && context.rule.name == 'required') {
                    return 'Select any relation type';
                }
                if (context.field == 'cvv') {
                    return context.rule.name == 'required' ? 'This field is required' : 'Enter valid CVV';
                }
                if (context.field == 'user_types' && context.rule.name == 'required') {
                    return 'Please select profile type';
                }
                if (context.field == 'dob' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'country' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'state' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'user_account_types' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'recaptcha' && context.rule.name == 'required') {
                    return 'Please verify the recaptcha';
                }
                if (context.field == 'primary_sports' && context.rule.name == 'required') {
                    return 'Select your Primary Sports';
                }
                if (context.field == 'file' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'status' && context.rule.name == 'optional') {
                    return `This field is required`;
                }
                if (context.field == 'refund_amount' && context.rule.name == 'required') {
                    return 'Enter the Refund Amount';
                }
                if (context.field == 'refund_reason' && context.rule.name == 'required') {
                    return 'Enter the Refund Reason';
                }
                if (context.field == 'refund_amount' && context.rule.name == 'max_value') {
                    return 'Refund Amount is greater than the original amount';
                }
                if (context.field == 'arrive_minutes' && context.rule.name == 'max_value') {
                    return 'The value should not exceed 1440 minutes';
                }
                if (context.field == 'package_type' && context.rule.name == 'required') {
                    return 'Select Package Type';
                }
                if (context.field == 'package_group' && context.rule.name == 'required') {
                    return 'Select Package Group';
                }
                if (context.field == 'age_group' && context.rule.name == 'required') {
                    return 'Select Age Group';
                }
                if (context.field == 'group_type' && context.rule.name == 'required') {
                    return 'Select Group Type';
                }
                if (context.field == 'notifications' && context.rule.name == 'required') {
                    return 'Please Choose Notifications';
                }
                if (context.field == 'password' && context.rule.name == 'required') {
                    return 'Enter New Password';
                }
                if (context.field == 'email' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'game_title' && context.rule.name == 'required') {
                    return 'Enter the Game Tittle';
                }
                if (context.field == 'team_a' && context.rule.name == 'required') {
                    return 'Please Select Team A';
                }
                if (context.field == 'team_b' && context.rule.name == 'required') {
                    return 'Please Select Team B';
                }
                if (context.field == 'game_date' && context.rule.name == 'required') {
                    return 'Enter the Game Date';
                }
                if (context.field == 'arrive_minutes' && context.rule.name == 'required') {
                    return 'Enter the Arrive Minutes';
                }
                if (context.field == 'time' && context.rule.name == 'required') {
                    return 'Enter the Game Time';
                }
                if (context.field == 'time_zone' && context.rule.name == 'required') {
                    return 'Select the Time Zone';
                }
                if (context.field == 'location' && context.rule.name == 'required') {
                    return 'Enter the Location';
                }
                if (context.field == 'retype_email' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'age_division' && context.rule.name == 'required') {
                    return 'This field is required';
                }
                if (context.field == 'year_of_experience' && context.rule.name == 'numeric') {
                    return 'Enter the numeric value only';
                }
                const message = messages[context.rule.name] ?
                    messages[context.rule.name] :
                    `Enter valid ${context.field}`;
                return message;
            },
            validateOnBlur: true,
            validateOnChange: true,
            validateOnInput: false,
            validateOnModelUpdate: true,
            bails: false,
        });

        defineRule('emailRequired', value => {
            if (!value || !value.length) {
                return 'Enter a valid email address';
            }
            return true;
        });


        defineRule('validemail', value => {
            const email = value.substring(value.indexOf("."));
            if (email.length > 5) {
                return 'Email address contain invalid characters';
            }
            return true;
        });
        defineRule('upperCase', value => {
            const regex = /[A-Z]/g;
            if (!regex.test(value)) {
                return 'Minimum of one capital letter';
            }
            return true;
        });
        defineRule('LowerCase', value => {
            const regex = /[a-z]/g;
            if (!regex.test(value) || value == undefined) {
                return 'Minimum of one small letter';
            }
            return true;
        });
        defineRule('NumberCase', value => {
            const regex = /[0-9]/g;
            if (!regex.test(value)) {
                return 'Minimum of one Number';
            }
            return true;
        });
        defineRule('NumberOnly', value => {
            const regex = /^[0-9]*$/g;
            if (!regex.test(value)) {
                return 'Enter Number Only';
            }
            return true;
        });
        defineRule('SpecialCase', value => {
            const regex = /[&*^%$#!@]/g;
            if (!regex.test(value)) {
                return 'Should have at least 1 special character (allowed &, *, ^, %, $, and #)';
            }
            return true;
        });
        defineRule('allowedCharacters', value => {
            const regex = /^[a-zA-Z0-9#$%\^&*!@]*$/g;
            if (!regex.test(value)) {
                return 'Enter allowed characters only( &, *, ^, %, $, and #)';
            }
            return true;
        });
        defineRule('creditCard', value => {
            const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g
            if (!regex.test(value)) {
                return 'Enter valid card number';
            }
            return true;
        });
        defineRule('privacy', value => {
            if (value) {
                return true;
            }
            return 'Please agree terms of service';
        });
        defineRule('requiredWaiver', (value, [waiver_signature_type]) => {
            if (waiver_signature_type.some(r => [1, 4, 5].indexOf(r) >= 0) && !(value)) {
                return 'Please acknowledge by clicking the waiver checkbox';
            }
            return true;
        });
        defineRule('confirmed', (value, [target]) => {
            if (value === target) {
                return true;
            }
            return 'The Confirm Password does not match with New Password';
        });
        defineRule('selectTeam', (value, [target]) => {
            if (value === target) {
                return 'Team A should not be same as Team B, Choose another team';
            }
            return true;
        });
        defineRule('gameTeamsTypeValidation', value => {
            if (!value || !value.length) {
                return 'Select any one of the Team option';
            }
            return true;
        });
        defineRule('gameNotificationValidation', value => {
            if (!value || !value.length) {
                return 'Select any one of the Notification option';
            }
            return true;
        });
        defineRule('genderRequired', value => {
            if (!value || !value.length) {
                return 'This field is required';
            }
            return true;
        });
        defineRule("zipCountry", (value, [country]) => {
            if (country == 'USA') {
                const isValidUSZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
                if (!isValidUSZip) {
                    return 'Enter valid US zip code'
                }
            }

            if (country === 'Canada') {
                const isValidCanadaZip = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(value);
                if (!isValidCanadaZip) {
                    return 'Enter a valid Canadian postal code';
                }
            }
            return true;
        });
        defineRule('MobileNumber', value => {

            if (!value.length) {
                return true;
            }
            if (value.length <= 9 || value.length > 20) {
                return 'Enter valid Mobile Number';
            }
            return true;
        });

        defineRule('PhoneNumber', value => {

            if (!value.length) {
                return true;
            }
            if (value.length <= 9 || value.length > 20) {
                return 'Enter valid Phone Number';
            }
            return true;
        });
        defineRule('Elderdob', value => {
            var date = moment().diff(value, 'years');
            if (date < 18) {
                return 'Age must be 18 and above ';
            }
            return true;
        });
        defineRule('ElderdobForIndividual', (value, [seasonStartDate]) => {
            let currentYear = new Date().getFullYear();
            let birthYear = new Date(value).getFullYear();
            if (seasonStartDate) {
                currentYear = new Date(seasonStartDate).getFullYear();
            }
            let date = currentYear - birthYear;
            if (date < 18) {
                return 'Age must be 18 and above ';
            }
            return true;
        });
        defineRule('Teamage', (value, [ageStart = 0, ageEnd = 100]) => {
            let year = new Date(value).getFullYear();
            let currentYear = new Date().getFullYear();
            let age = currentYear - year;
            if (age > ageStart && age < ageEnd) {
                return true;
            }
            let s = parseInt(ageStart) + 1;
            let e = parseInt(ageEnd) - 1;
            return 'Enter the age between ' + s + '-' + e;
        });
        defineRule('Youngerdob', (value, [seasonStartDate], field_name) => {
            let msg = 'Age must be under 18';
            let currentYear = new Date().getFullYear();
            let birthYear = new Date(value).getFullYear();
            if (field_name != '') {
                msg = 'user age should be age 18 below.'
            }
            if (seasonStartDate) {
                currentYear = new Date(seasonStartDate).getFullYear();
                msg = 'Age must be under 18';
            }
            let date = currentYear - birthYear;
            if (date >= 18) {
                return msg;
            }
            return true;
        });
        defineRule('18UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 18) {
                return 'Age must be under 18 ';
            }
            return true;
        });
        defineRule('16UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 16) {
                return 'Age must be under 16 ';
            }
            return true;
        });
        defineRule('14UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 14) {
                return 'Age must be under 14 ';
            }
            return true;
        });
        defineRule('12UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 12) {
                return 'Age must be under 12 ';
            }
            return true;
        });
        defineRule('10UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 10) {
                return 'Age must be under 10 ';
            }
            return true;
        });
        defineRule('8UDob', (value, [seasonStartDate]) => {
            var age = calculateAge(value, seasonStartDate);
            if (age > 8) {
                return 'Age must be under 8 ';
            }
            return true;
        });
        defineRule('Childdob', (value, field_name) => {
            var date = moment().diff(value, 'years');
            let msg = 'Age must be under 12';
            if (field_name != '') {
                msg = field_name + ' user age should be age 12 below.'
            }
            if (date > 12) {
                return msg;
            }
            return true;
        });
        defineRule('ChildAthletedob', (value, field_name, seasonStartDate = null) => {
            let currentYear = new Date().getFullYear();
            let birthYear = new Date(value).getFullYear();
            let msg = 'Age must be under 12';
            if (field_name.length == 2) {
                let seasonStartDate = field_name[field_name.length - 1];
                currentYear = new Date(seasonStartDate).getFullYear();
                field_name.pop();
            }
            if (field_name != '') {
                msg = field_name + ' user age should be age 12 below.'
            }
            var age = currentYear - birthYear;
            if (age > 12) {
                return msg;
            }
            return true;
        });
        defineRule('YoungerAthletedob', (value, field_name, seasonStartDate = null) => {
            let currentYear = new Date().getFullYear();
            let birthYear = new Date(value).getFullYear();
            if (field_name.length == 3) {
                let seasonStartDate = field_name[field_name.length - 1];
                currentYear = new Date(seasonStartDate).getFullYear();
                field_name.pop();
            }
            var age = currentYear - birthYear;
            let msg = 'Age must be under 18';
            if (field_name != '') {
                msg = field_name + ' user age should be age 18 below.'
            }
            if (age > 18) {
                return msg;
            }
            return true;
        });

        defineRule('requiredIf', (value, [cond_value]) => {
            if (!value || !value.length) {
                if (cond_value) return 'This field is required';
            }
            return true;
        });

        defineRule('requireIfCon', (value, [cond_field_val, cond_val]) => {
            if (!value && cond_field_val == cond_val) {
                return 'This field is required'
            }
            return true;
        })

        defineRule('requireIfConIn', (value, [cond_field_val, cond_val]) => {
            let condVal = cond_val.split("-")
            let cond_field_val_text = cond_field_val.toString();
            if (condVal.indexOf(cond_field_val_text) !== -1 && (value == '' || !value)) {
                return 'This field is required'
            }

            return true;
        })

        defineRule('endDateValid', (value, [start_date]) => {
            if (start_date == '') return true;

            let endDate = moment(value).format('YYYY-MM-DD');
            let startDate = moment(start_date).format('YYYY-MM-DD');

            if (startDate > endDate) {
                return 'The end date can not be less than the start date';
            }
            return true;
        });

        defineRule('currency', value => {
            const regex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,3})?$/;
            if (!regex.test(value)) {
                return 'Enter the valid amount';
            }
            return true;
        });

        defineRule('percentage', (value, [discount_type]) => {
            if (discount_type == 2) {
                const regex = /^(?<whole>100|\d{1,2})(?<fract>\.\d+)?$/;
                if (!regex.test(value)) {
                    return 'Percentage value must be 1 to 100.';
                }
            }
            return true;
        });

        defineRule('time24', value => {
            const regex = /^([01]\d|2[0123]):(?:[012345]\d)$/;
            if (value != '' && value != null && !regex.test(value)) {
                return 'Enter the valid time Format (HH:mm)';
            }
            return true;
        });
        defineRule('alpha_numeric', value => {
            const isValidalpha_numeric = /^[ A-Za-z0-9]*$/.test(value);
            if (!isValidalpha_numeric) {
                return 'First Name must be alphanumeric only'
            }
            return true;
        });
        defineRule('eventTime', value => {
            const isValideventTime = /^\d+:\d{2}:\d{2}$/.test(value);
            if (!isValideventTime) {
                return 'Enter Valid Time'
            }
            return true;
        });
        defineRule('alphaChars', value => {
            const isValidloc = /^[ A-Za-z_@./#&+-]*$/.test(value);
            if (!isValidloc) {
                return 'Enter Valid Location'
            }
            return true;
        });

        defineRule('optional', (value, [field_name]) => {
            if (value != undefined && !value) {
                return `This field is required`;
            }
            return true;
        });

        defineRule('requiredSelect', (value, [field_name, message = false], context) => {
            field_name = initializeFieldName(field_name, context);
            let msg = `This field is required`;
            if (!value) {
                return message ? message : msg;
            }
            return true;
        });

        defineRule('requiredMultiSelect', (value, [field_name, message = false]) => {
            let msg = `This field is required`;
            if (!value.length) {
                return message ? message : msg;
            }
            return true;
        });
        defineRule('alpha_numericId', value => {
            const isValidalpha_numeric = /^[ A-Za-z0-9]*$/.test(value);
            if (!isValidalpha_numeric) {
                return 'This field must be alpha numeric only'
            }
            return true;
        });
        defineRule('donation_numeric', value => {
            const isValidalpha_numeric = /^[0-9]*$/.test(value);
            if (!isValidalpha_numeric) {
                return 'This field not contain decimal'
            }
            return true;
        });
        defineRule('must_required', (value, [field_name, message = false]) => {
            let msg = `This field is required`;
            if (!value) {
                return message ? message : msg;
            }
            return true;
        });
        defineRule('donate_min_value', value => {
            if (value < 5) {
                return 'Amount should not be less than $5'
            }
            return true;
        });
        defineRule('CheckAmountDuplicate', (value, [value1, value2, value3]) => {
            if (value1 == '' || value2 == '' || value3 == '') {
                return true;
            } else {
                value1 = (typeof value1 === 'undefined' || value1 == '') ? 99999996 : value1;
                value2 = (typeof value2 === 'undefined' || value2 == '') ? 99999997 : value2;
                value3 = (typeof value3 === 'undefined' || value3 == '') ? 99999996 : value3;

                let dup = containsDuplicates([parseInt(value1), parseInt(value2), parseInt(value3)]);
                return dup ? 'The same donation amount cannot be entered more than once' : true;
            }
        });

        defineRule('monthYearExpire', (value, [month, year]) => {

            let currentMonth = new Date().getMonth() + 1;
            let currentYear = new Date().getFullYear();
            if (parseInt(year) < currentYear || (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
                return 'Please select valid future month and year';
            }
            return true;
        });

        defineRule('requiredWaiverInitials', (value, [waiver_signature_type]) => {
            let alpha = /^[A-Za-z]+$/;
            if (waiver_signature_type.some(r => [2, 4].indexOf(r) >= 0) && !(value)) {
                return 'Please enter initials';
            } else if (waiver_signature_type.some(r => [3, 5].indexOf(r) >= 0) && !(value)) {
                return 'Please enter username';
            } else if (waiver_signature_type.some(r => [2, 4].indexOf(r) >= 0) && !(value.match(alpha))) {
                return 'Initials must be alphabets only';
            } else if (waiver_signature_type.some(r => [3, 5].indexOf(r) >= 0) && !(value.match(alpha))) {
                return 'Username must be alphabets only';
            } else if (waiver_signature_type.some(r => [2, 3, 4, 5].indexOf(r) >= 0) && value.length < 2) {
                return 'Please enter minimum of two characters';
            }
            return true;
        });
        defineRule('confirmedEmail', (value, [target]) => {
            if (value === target) {
                return true;
            }
            return 'The email id does not match';
        });
        defineRule('enterTheFormatRule', (value, [field_name], context) => {
            field_name = initializeFieldName(field_name, context);
            if ((value != undefined && !value) || value == null) {
                return `This field is required`;
            }
            return true;
        });
        defineRule('requiredEither', (value, [cond_value]) => {
            if ((value != undefined && !value) || value == null) {
                if ((cond_value == undefined && !cond_value) || cond_value == null) {
                    return `This field is required`;
                }

            }
            return true;
        });
        defineRule('radioRequired', value => {
            if (!value || !value.length) {
                return 'This field is required';
            }
            return true;
        });
        defineRule('confirmedInitials', (value, [target]) => {
            if (value === target) {
                return true;
            }
            return 'The initials does not match';
        });

        defineRule('notEqual', (value, [target]) => {
            if (value != target) {
                return true;
            }
            return 'Please ensure that the parent email and account email are not the same.';
        });

        defineRule('ExpiredDate', (value, [target]) => {
            let currentMonth = new Date().getMonth() + 1;
            let currentYear = new Date().getFullYear();
            if (parseInt(target) < currentYear || (parseInt(target) === currentYear && parseInt(value) < currentMonth)) {
                return "Your card's expiration month is invalid.";
            }
            return true;
        });

        defineRule('requiredUnless', (value, [cond_value]) => {
            if (!value || !value.length) {
                if (!cond_value) return 'This field is required';
            }
            return true;
        });
        defineRule('urlValidation', value => {
            const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if (!urlPattern.test(value) && value) {
                return "Enter url with http or https";
            }
            return true;
        });
        defineRule('yearValidation', value => {
            const currentYear = new Date().getFullYear();
            if (/^\d{4}$/.test(value) && parseInt(value) <= currentYear) {
                return true;
            }
            return "Fill Correct Year";
        });
        defineRule('fileValid', value => {
            const yearPattern = /^\d{4}$/;
            if (yearPattern.test(value) && value >= 1000 && value <= 9999) {
                return ""
            }
            return true;
        });
        defineRule('weightFloatValidation', value => {
            const regex = /^-?\d+(\.\d{1,3})?$/;
            if (!value || !value.length) {
                return true;
            }
            if (!regex.test(value)) {
                return "Enter valid weight"
            }
            return true;
        });

        defineRule('TeamageLessYear', (value, [ageStart = 0, ageEnd = 100]) => {
            let year = new Date(value).getFullYear();
            let currentYear = new Date().getFullYear();
            let endAge = currentYear - year - 1;
            let age = currentYear - year;
            if (age > ageStart && endAge < ageEnd) {
                return true;
            }
            let s = parseInt(ageStart) + 1;
            let e = parseInt(ageEnd) - 1;
            return 'Enter the age between ' + s + '-' + e;
        });


        defineRule('divisionDOB', (value, [divisionAge = 99, seasonStartDate]) => {
            let age = moment(seasonStartDate).startOf("year").diff(value, "year", true);
            if (age > divisionAge) {
                return 'Age must be under ' + divisionAge;
            }
            return true;
        });

        defineRule('adultDOB', (value, [minAge = 18, seasonStartDate]) => {
            let age = moment(seasonStartDate).startOf("year").diff(value, "year", true);
            if (age < minAge) {
                return 'Age must be above or equal ' + minAge;
            }
            return true;
        });

        defineRule('termsvalidate', (checkboxVal, [inputVal]) => {
            if (checkboxVal === '' && !inputVal) {
                return 'Enter input value & Please acknowledge by checking the box'
            } else if (checkboxVal === '' && inputVal) {
                return 'Enter input value'
            } else if (checkboxVal !== '' && !inputVal) {
                return 'Please acknowledge by checking the box'
            } else {
                return true;
            }
        });

        defineRule('ageValidation', (value, [ageEnd = 100]) => {
            let year = new Date(value).getFullYear();
            let currentYear = new Date().getFullYear();
            let endAge = currentYear - year - 1;
            if (endAge < ageEnd) {
                return true;
            }
            return 'Age must be under '+ parseInt(ageEnd);
            
        });

        defineRule('ageBetween', (value, [ageStart = 0, ageEnd = 100]) => {
            let age = moment().diff(moment(value, "MM/DD/YYYY"), "years");
            if (age >= ageStart && age <= ageEnd) {
                return true;
            }
            return 'Age must be between '+ ageStart + " and " + ageEnd + ". Age is " + age + " now.";
        });
    },
};